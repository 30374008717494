// import React, {Suspense, lazy} from 'react';

import './App.css';
import Header from './Components/Commons/Header/Header';
import NavBar from './Components/Commons/NavBar/NavBar';

import Home from './Components/Home/Home';
import Gastronomia from './Components/Gastronomia/Gastronomia'
import QuienesSomos from './Components/QuienesSomos/QuienesSomos'
import Locales from './Components/Locales/Locales'
import Promos from './Components/Promos/Promos'
import Footer from './Components/Commons/Footer/Footer';

import { Routes, Route, HashRouter } from 'react-router-dom'
import { SiteContextProvider } from './Context/SiteContext';
import DondeEstamos from './Components/DondeEstamos/DondeEstamos';

function App() {

  return (
    <div> 
      <SiteContextProvider>
        <HashRouter>
            <Header />
            <NavBar />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/quienes-somos/" element={<QuienesSomos />} />
              <Route path="/gastronomia/" element={<Gastronomia />} />
              <Route path="/locales/" element={<Locales />} />
              <Route path='/promos/' element={<Promos />} />
              <Route path='/donde-estamos/' element={<DondeEstamos />} />
              <Route path="*" element={<h2 className='text-center p-5'>Página no encontrada.</h2>}/>            
            </Routes>
            <Footer />
            {/* <FooterImages /> */}
        </HashRouter>
      </SiteContextProvider>
    </div>
  );
}
export default App;
