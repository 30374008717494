import React, { useState,  useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../../../Styles/main.css'

const Header = () => {

    const [loading, setLoading ] = useState(true)

    useEffect(() => {
        setTimeout ( ()=>{
            setLoading(false)
        }, 1000 ) 
    },[])
    
    if(loading) { return <h1 className="container text-center p-4"><i className="fas fa-spinner fa-spin"></i></h1> }

    return (
        
        <header className='container'>
            
            <div className='row justify-center'>
                
                <div className='animated fast fadeIn'>
                    <Link to={'/'}>
                        <img src="./images/header/las-alodnras-home.jpg" 
                        className='header__lasAlondras--img ' 
                        alt="Vista de Paseo de Las Alondras Carilo" />
                     </Link>
                </div>
                
                {/* <div className='col-md-12 text-center'>
                    <Link to={'/'}>
                        <img src="./images/header/las-alondras-carilo.png" 
                        className='header__lasAlondras nimated fast fadeIn' alt="Las Alondras Carilo - Logo" />
                    </Link>
                </div> */}
                
            </div>
            
        </header>
       
    )
}

export default Header
