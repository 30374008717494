import React from 'react'
import { Carousel } from 'react-bootstrap'
import '../../../Styles/main.css'

const CarouselContainerQuienesSomos = () => {
  return (
    <section>
      
      <Carousel className='carousel__box  animated fast fadeIn' indicators={ false } >
        
        <Carousel.Item>
          <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-01.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
            {/* <img className="d-block w-100 d-lg-none" src="./images/quienes-somos/carousel-01m.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy" /> */}
        </Carousel.Item>

        <Carousel.Item >
            <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-02.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
        </Carousel.Item>

        <Carousel.Item >
            <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-03.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
        </Carousel.Item>

        <Carousel.Item >
            <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-04.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
        </Carousel.Item>

        <Carousel.Item >
            <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-05.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
        </Carousel.Item>

        <Carousel.Item >
            <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-06.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
        </Carousel.Item>

        <Carousel.Item >
            <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-07.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
        </Carousel.Item>

        <Carousel.Item >
            <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-08.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
        </Carousel.Item>

        <Carousel.Item >
            <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-09.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
        </Carousel.Item>

        <Carousel.Item >
            <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-10.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
        </Carousel.Item>

        <Carousel.Item >
            <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-11.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
        </Carousel.Item>

        <Carousel.Item >
            <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-12.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
        </Carousel.Item>

        <Carousel.Item >
            <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-13.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
        </Carousel.Item>

        <Carousel.Item >
            <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-14.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
        </Carousel.Item>

        <Carousel.Item >
            <img className="d-block w-100 carousel__box--image" src="./images/quienes-somos/carousel-15.jpg" alt="Paseo de compras Las Alondras Cariló" loading="lazy"/>
        </Carousel.Item>

        </Carousel>
    </section>
  )
}

export default CarouselContainerQuienesSomos