import React, { useContext, useState, useEffect } from 'react'
import Loading from '../Loading/Loading'
import SiteContext from '../../Context/SiteContext'
import '../../Styles/main.css'

const Gastronomia = () => {

    const {setSection} = useContext(SiteContext);
    setSection('Gastronomia');

    const [loading, setLoading ] = useState(true)
    useEffect(() => {
        setTimeout ( ()=>{
            setLoading(false)
        }, 1000 ) 
    },[])

    if(loading) { return <Loading /> }

    return (
        <section>
            <div className='container-fluid p-0'>
                <img src="./images/gastronomia/header_gastronomia.jpg" className='d-block w-100 header__images animated fast fadeIn' alt="Gastronomia en Las Alondras Carilo" />            
            </div>
             <div className='container'>

                <div>
                    <h1 className='quienesSomos__titulo'>
                        <img src="./images/quienes-somos/logo-200x200.jpg" className='d-block' alt='Las Alondras Cariló' />
                        GASTRONOMIA
                    </h1>
                </div>
                
                <div className='row'>
                    <div className='col-md-12'>
                        <p className='quienesSomos__texto'>
                        En Las Alondras Cariló vas a pasar Momentos Especiales con nuestras propuestas gastronómicas, repostería Gourmet, la auténtica Pizza Napolitana, el mejor Sushi del país, cremosos Helados Artesanales y los más ricos Waffles de la costa te están esperando!<br />
                        </p>
                    </div>
                </div>
                <div className='row gastronomia__container '>
                    
                    <div className='gastronomia__column'>
                        
                        <img src="./images/gastronomia/tante_1.jpg" className='gastronomia__column--item gastronomia__column--itemBottom' alt="Local de Tante en Las Alondras Carilo" />
                        <a href='https://www.instagram.com/tante.restaurant/' target={'_blank'} rel='noreferrer noopener' className='gastronomia__column--item gastronomia__column--itemRight' >
                            <img src="./images/gastronomia/tante_2.jpg" className='gastronomia__column--item--img' alt="Logo de Tante en Las Alondras Carilo"/>
                        </a>
                        <img src="./images/gastronomia/tante_3.jpg" className='gastronomia__column--item gastronomia__column--itemTop'   alt="Producto ofrecido por Tante" />

                    </div>

                    <div className='gastronomia__column'>
                        <img src="./images/gastronomia/peppe_1.jpg" className='gastronomia__column--item' style={{borderBottom: 'none'}} alt="Local de Peppe Napoli en Las Alondras Carilo" />
                        <a href='https://www.instagram.com/peppenapoliok/' target={'_blank'} rel='noreferrer noopener' className='gastronomia__column--item gastronomia__column--itemRight' >
                            <img src="./images/gastronomia/peppe_2.jpg" className='gastronomia__column--item--img'  alt="Logo de Peppe Napoli en Las Alondras Carilo" />
                        </a>
                        <img src="./images/gastronomia/peppe_3.jpg" className='gastronomia__column--item' style={{borderTop: 'none'}}  alt="Pizza ofrecida por Peppe Napli" />
                    </div>

                    <div className='gastronomia__column'>
                        <img src="./images/gastronomia/sushi_1.jpg" className='gastronomia__column--item' style={{borderBottom: 'none'}} alt="Local de Sushi Club en Las Alondras Carilo" />
                        <a href='https://www.instagram.com/sushiclubcarilo/' target={'_blank'} rel='noreferrer noopener' className='gastronomia__column--item gastronomia__column--itemRight' >

                            <img src="./images/gastronomia/sushi_2.jpg" className='gastronomia__column--item--img'  alt="Logo de Sushi Club en Las Alondras Carilo" />
                        </a>
                        <img src="./images/gastronomia/sushi_3.jpg" className='gastronomia__column--item' style={{borderTop: 'none'}} alt="Producto ofrecido por Sushi Club" />
                    </div>

                    <div className='gastronomia__column'>
                        <img src="./images/gastronomia/colonial_1.jpg" className='gastronomia__column--item' style={{borderBottom: 'none'}} alt="Local de Colonial Helado en Las Alondras Carilo" />
                        <a href='https://www.instagram.com/colonialhelados/' target={'_blank'} rel='noreferrer noopener' className='gastronomia__column--item gastronomia__column--itemRight' >
                            <img src="./images/gastronomia/colonial_2.jpg" className='gastronomia__column--item--img'  alt="Logo de Colonial Helado en Las Alondras Carilo" />
                        </a>
                        <img src="./images/gastronomia/colonial_3.jpg" className='gastronomia__column--item' style={{borderTop: 'none'}}  alt="Producto ofrecido por Colonial Helado" />
                    </div>

                    <div className='gastronomia__column'>
                        <img src="./images/gastronomia/mcbar_1.jpg" className='gastronomia__column--item' style={{borderBottom: 'none'}} alt="Local de Mc Bar en Las Alondras Carilo" />
                        <a href='https://www.instagram.com/' target={'_blank'} rel='noreferrer noopener' className='gastronomia__column--item gastronomia__column--itemRight' >
                            <img src="./images/gastronomia/mcbar_2.jpg" className='gastronomia__column--item--img'  alt="Logo de Mc Bar en Las Alondras Carilo" />
                        </a>
                        <img src="./images/gastronomia/mcbar_3.jpg" className='gastronomia__column--item' style={{borderTop: 'none'}}  alt="Producto ofrecido por Mc Bar" />
                    </div>
                    
                </div>
            </div>
            <div className='container-fluid p-0 locales__back'>
                <div className='row justify-center-content plano__container'>
                    <div className='col-md-12'>
                        <img  src="./images/separador_bottom.jpg" className='plano__imagen--separador' alt="Separador decoración" />
                    </div>
                    
                    <div className='col-md-2 p-0 m-0 d-none d-lg-block'>
                        <img    src="./images/gastronomia/banner__planos--izquierda.jpg" 
                                className='plano__imagen--lateral'  alt="Locales Gastronomicos" 
                        />
                    </div>
                    <div className='col-md-8 p-0 m-0 plano__imagen--plano--container'>
                        <img    src="./images/gastronomia/plano-gastronomia-full.jpg" 
                                className='plano__imagen--plano'  alt="Plano de locales Gastronomicos" 
                        />
                    </div>
                    <div className='col-md-2 p-0 m-0 d-none d-lg-block'>
                        <img    src="./images/gastronomia/banner__planos--derecha.jpg" 
                                className='plano__imagen--lateral'  alt="Locales Gastronomicos" 
                        />
                    </div>
                </div>    

                <div className='row quienesSomos__img--footer quienesSomos__img--footerLocales'>
                    
                </div>

            </div> 
        </section>
    )
}

export default Gastronomia
