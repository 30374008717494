import React, { useState, useEffect } from 'react'
import Loading from '../Loading/Loading'
import Banner from '../Banner/Banner'
import '../../Styles/main.css'

const Promos = () => {

    const [loading, setLoading ] = useState(true)

    useEffect(() => {
        setTimeout ( ()=>{
            setLoading(false)
        }, 1000 ) 
    },[])

    if(loading) { return <Loading /> }

    return (
        <section>
            <div className='container-fluid p-0'>
                <img src="./images/promos/header_promos.jpg" className='d-block w-100 header__images ' alt="Promociones de los Locales de Las Alondras Carilo" />            
            </div>
            <div className='container'>

                <h1 className='quienesSomos__titulo'>
                    <img src="./images/quienes-somos/logo-200x200.jpg" className='d-block' alt='Las Alondras Cariló' />
                    PROMOCIONES
                </h1>
          
                <div className='row'>
                    <div className='col-md-12'>
                        <p className='quienesSomos__texto'>
                        En Las Alondras Cariló disfrutá de los beneficios exclusivos en los locales adheridos con las tarjetas ICBC y las promos de cada local en efectivo.<br />
                        </p>
                    </div>
                </div>
                <div className='row justify-content-center banners__columns'>
                    
                    <div className='col-6 col-sm-6 col-md-4 col-lg-4'>
                        <Banner 
                                className=''
                                src={'./images/promos/promos-mcbar.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'Promocion de Mc Bar'} />
                    </div>
                    <div className='col-6 col-sm-6 col-md-4 col-lg-4'>
                        <Banner 
                                className=''
                                src={'./images/promos/promos-peppe.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'Promocion de Peppe Napoli'} />
                    </div>
                 </div>

                 <div className='row justify-content-center banners__columns  banners__columns--bottom'>   

                    <div className='col-6 col-sm-6 col-md-3 col-lg-3'>
                        <Banner 
                                className=''
                                src={'./images/promos/promos-mistura.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'Promocion de Mistura'} />
                    </div>

                    <div className='col-6 col-sm-6 col-md-3 col-lg-3'>
                        <Banner 
                                className=''
                                src={'./images/promos/promos-modo-avion.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'Promocion de Modo Avion'} />
                    </div>

                    <div className='col-6 col-sm-6 col-md-3 col-lg-3'>
                        <Banner 
                                className=''
                                src={'./images/promos/promos-la-almeja.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'Promocion de La Almeja'} />
                    </div>

                    <div className='col-6 col-sm-6 col-md-3 col-lg-3'>
                        <Banner 
                                className=''
                                src={'./images/promos/promos-lovely.jpg' }
                                link= {'sinLink'}
                                target={'_blank'}
                                section='locales'
                                descr={'Promocion de Lovely Denim'} />
                    </div>

                </div>
                
            </div>

            <div className='container-fluid p-0 locales__back'>

                <div className='row justify-center-content plano__container'>

                    <div className='col-md-12'>
                        <img  src="./images/separador_bottom.jpg" className='plano__imagen--separador' alt="Separador decoración" />
                    </div>
                    
                    <div className='col-md-2 p-0 m-0 d-none d-lg-block'>
                        <img    src="./images/promos/banner__planos--izquierda.jpg" 
                                className='plano__imagen--lateral'  alt="Imagenes de Locales con Promoss" 
                        />
                    </div>
                    
                    <div className='col-md-8 p-0 m-0 plano__imagen--plano--container'>
                        <img    src="./images/promos/plano-promos-full.jpg" 
                                className='plano__imagen--plano'  alt="Plano de Locales con Promos" 
                        />
                    </div>
                    
                    <div className='col-md-2 p-0 m-0 d-none d-lg-block'>
                        <img    src="./images/promos/banner__planos--derecha.jpg" 
                                className='plano__imagen--lateral'  alt="Imagenes de Locales con Promos" 
                        />
                    </div>

                </div>    
                

                <div className='row quienesSomos__img--footer quienesSomos__img--footerLocales'>
                    
                </div>

            </div> 

        </section>
    )
}

export default Promos
