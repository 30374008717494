import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

const FooterImages = () => {

    const [show, setShow] = useState(false);
    
    const handleClose = () => { setShow(false) }
    const handleShow = () => { setShow(true) }

    return (


    <div className='container'>

        <Modal
            show={show}
            size='lg'
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header closeButton>
                <Modal.Title>Las Alondras Cariló.</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <iframe 
                    width="100%" 
                    height="415" 
                    src="https://www.youtube.com/embed/gpOuqwJqXBM?&autoplay=1" 
                    title="Las Alondras Carilo"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
            </Modal.Body>

            {/* <Modal.Footer className={'modal__desc'} >
                <small>Video corto aereo de Las Alondras Cariló</small>
            </Modal.Footer> */}
        </Modal>

        <div className='row pb-1'>
            
            <img src="./images/separador-horizontal.jpg" className='w-100 mb-4 mt-2 d-none d-lg-block' alt="Separador" />
            <img src="./images/separador-horizontal-mobile.jpg" className='w-100 mb-5 mb-3 d-lg-none' alt="Separador" />
                               
            {/* <div className="footerItem col-6 col-sm-6 col-md-3 mb-4">
                <img src="./images/home/thumb-video-playa.jpg" className="d-block w-100" alt="Horarios en que estamos abierto.s" />
            </div>
            <div className="footerItem col-6 col-sm-6 col-md-3 mb-4">
                <img src="./images/home/thumb-video-bosque.jpg" className="d-block w-100" alt="Horarios en que estamos abierto.s"></img>
            </div>
            <div className="footerItem col-6 col-sm-6 col-md-3">
                <img src="./images/home/thumb-locales.jpg" className="d-block w-100" alt="Horarios en que estamos abierto.s"></img> 
            </div>
            <div className="footerImg__over footerItem col-6 col-sm-6 col-md-3">
                <a  onClick={ () => handleShow() } > 
                    <img src="./images/home/thumb-paseando.jpg" className="d-block w-100" alt="Horarios en que estamos abierto.s"></img> 
                </a> 
            </div> */}

        </div>
    </div>
  )
  
}

export default FooterImages