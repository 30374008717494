import React, {useState} from 'react'
import { Modal } from 'react-bootstrap'
import CarouselHomeGallery from '../Carousel/CarouselHomeGallery';

import './HomeGallery.css'

const HomeGallery = () => { 


    // --------- INICIO DE LOGICA DE CAROUSEL --------- //

    const [show, setShow] = useState(false);
    const [idImg, setIdImg] = useState();

    const handleClose = () => { setShow(false); }
    const handleShow = (idPic) => {
        setShow(true)
        setIdImg(idPic)
        console.log('setIdImg()')

    }

    // --------- FIN DE LOGICA DE CAROUSEL --------- //


    return (

        <div>
            <div className='container'>

                <Modal show={show} size='lg' onHide={handleClose} ackdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title>Las Alondras Carilo.</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <CarouselHomeGallery index={ idImg }/>
                    </Modal.Body>

                    <Modal.Footer className={'modal__desc'} >
                        <small>Imágenes de nuestras instalaciones.</small>
                    </Modal.Footer>
                </Modal>

                <div className='row pb-1'>
                    
                    <img src="./images/separador-horizontal.jpg" className='w-100 mb-2 d-none d-lg-block' alt="Separador" />
                    <img src="./images/separador-horizontal-mobile.jpg" className='w-100 mb-5 mb-3 d-lg-none' alt="Separador" />
                    
                    <div className="gallery__cont p-2 pb-4">
                        <div className="column">
                            <img onClick={ () => handleShow(0) }src="images/home/gallery/gallery-01.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="0" />
                            <img onClick={ () => handleShow(1) } src="images/home/gallery/gallery-02.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="1" />
                            <img onClick={ () => handleShow(2) } src="images/home/gallery/gallery-03.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="2" />
                            <img onClick={ () => handleShow(3) } src="images/home/gallery/gallery-04.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="3" />
                            <img onClick={ () => handleShow(4) } src="images/home/gallery/gallery-05.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="4" />
                            <img onClick={ () => handleShow(5) } src="images/home/gallery/gallery-06.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="5" />
                            <img onClick={ () => handleShow(6) } src="images/home/gallery/gallery-07.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="6" />
                            <img onClick={ () => handleShow(8) } src="images/home/gallery/gallery-09.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="8" />
                            <img onClick={ () => handleShow(7) } src="images/home/gallery/gallery-08.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="7" />
                            <img onClick={ () => handleShow(9) } src="images/home/gallery/gallery-10.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="9" />
                        </div>
                        <div className="column">
                            <img onClick={ () => handleShow(10) } src="images/home/gallery/gallery-11.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="10" />
                            <img onClick={ () => handleShow(11) } src="images/home/gallery/gallery-12.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="11" />
                            <img onClick={ () => handleShow(12) } src="images/home/gallery/gallery-13.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="12" />
                            <img onClick={ () => handleShow(13) } src="images/home/gallery/gallery-14.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="13" />
                            <img onClick={ () => handleShow(14) } src="images/home/gallery/gallery-15.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="14" />
                            <img onClick={ () => handleShow(15) } src="images/home/gallery/gallery-16.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="15" />
                            <img onClick={ () => handleShow(16) } src="images/home/gallery/gallery-17.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="16" />
                            <img onClick={ () => handleShow(17) } src="images/home/gallery/gallery-18.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="17" />
                            <img onClick={ () => handleShow(18) } src="images/home/gallery/gallery-19.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="18" />
                            <img onClick={ () => handleShow(19) } src="images/home/gallery/gallery-20.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="19" />
                            <img onClick={ () => handleShow(20) } src="images/home/gallery/gallery-21.jpg" className="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="20" />
                        </div>
                        <div className="column">
                            <img onClick={ () => handleShow(21) } src="images/home/gallery/gallery-22.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="21" />
                            <img onClick={ () => handleShow(22) } src="images/home/gallery/gallery-23.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="22" />
                            <img onClick={ () => handleShow(23) } src="images/home/gallery/gallery-24.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="23" />
                            <img onClick={ () => handleShow(24) } src="images/home/gallery/gallery-25.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="24" />
                            <img onClick={ () => handleShow(25) } src="images/home/gallery/gallery-26.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="25" />
                            <img onClick={ () => handleShow(26) } src="images/home/gallery/gallery-27.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="26" />
                            <img onClick={ () => handleShow(27) } src="images/home/gallery/gallery-28.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="27" />
                            <img onClick={ () => handleShow(28) } src="images/home/gallery/gallery-29.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="28" />
                            <img onClick={ () => handleShow(29) } src="images/home/gallery/gallery-30.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="29" />
                        </div>
                        
                        <div className="column">
                            <img onClick={ () => handleShow(30) } src="images/home/gallery/gallery-31.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="30" />
                            <img onClick={ () => handleShow(31) } src="images/home/gallery/gallery-32.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="31" />
                            <img onClick={ () => handleShow(32) } src="images/home/gallery/gallery-33.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="32" />
                            <img onClick={ () => handleShow(33) } src="images/home/gallery/gallery-34.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="33" />
                            <img onClick={ () => handleShow(34) } src="images/home/gallery/gallery-35.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="34" />
                            <img onClick={ () => handleShow(35) } src="images/home/gallery/gallery-36.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="35" />
                            <img onClick={ () => handleShow(36) } src="images/home/gallery/gallery-37.jpg" className ="d-block" alt="Imagenes de La Alondras Carilo" data-img-mostrar="36" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default HomeGallery