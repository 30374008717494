import React from 'react'
import '../../Styles/main.css'

const DondeEstamos = () => {
  return (
    <section>

        <div className='container-fluid p-0'>
            <img src="./images/donde-estamos/header_donde-estamos.jpg" className='d-block w-100 header__images animated fast fadeIn' alt="Vista aerea de Las Alondras Carilo" />            
            
            <div className='container'>

                <div>
                    <h1 className='quienesSomos__titulo'>
                        <img src="./images/quienes-somos/logo-200x200.jpg" className='d-block' alt='Las Alondras Cariló' />
                        DONDE ESTAMOS
                    </h1>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <p className='quienesSomos__texto'>
                        Nos encontramos sobre la Avenida Divisadero entre Cerezo y Avellano mirando al bosque, con un amplio frente para estacionar, en el corazón del centro de Cariló cerca de todo te esperamos para disfrutar Momentos especiales durante todo el año.<br />
                        </p>
                    </div>
                </div>

                <div className='row donde__estamos_container'>
                    <div className='donde__estamos_col p-0 m-0'><img className='d-block w-100' src="./images/donde-estamos/donde-estamos-icon.jpg" alt=""/></div>
                    <div className='donde__estamos_col p-0 m-0'><img className='d-block w-100' src="./images/donde-estamos/donde-estamos-foto-01.jpg" alt="" /></div>
                    <div className='donde__estamos_col p-0 m-0'><img className='d-block w-100' src="./images/donde-estamos/donde-estamos-foto-02.jpg" alt="" /></div>
                    <div className='donde__estamos_col p-0 m-0'><img className='d-block w-100' src="./images/donde-estamos/donde-estamos-foto-03.jpg" alt="" /></div>
                    <div className='donde__estamos_col p-0 m-0'><img className='d-block w-100' src="./images/donde-estamos/donde-estamos-foto-04.jpg" alt="" /></div>
                    <div className='donde__estamos_col p-0 m-0'><img className='d-block w-100' src="./images/donde-estamos/donde-estamos-foto-05.jpg" alt="" /></div>
                    <div className='donde__estamos_col p-0 m-0'><img className='d-block w-100' src="./images/donde-estamos/donde-estamos-foto-06.jpg" alt="" /></div>
                    <div className='donde__estamos_col p-0 m-0'><img className='d-block w-100' src="./images/donde-estamos/donde-estamos-foto-07.jpg" alt="" /></div>
                    <div className='donde__estamos_col p-0 m-0'><img className='d-block w-100' src="./images/donde-estamos/donde-estamos-foto-08.jpg" alt="" /></div>
                    <div className='donde__estamos_col p-0 m-0'><img className='d-block w-100' src="./images/donde-estamos/donde-estamos-foto-09.jpg" alt="" /></div>
                </div>

                <div>
                    <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3179.4589362986176!2d-56.903667999999996!3d-37.16556299999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959c9debee7b7d49%3A0x3e45a77ae9c42736!2sPaseo%20Las%20Alondras%20Caril%C3%B3!5e0!3m2!1ses-419!2sar!4v1647609109272!5m2!1ses-419!2sar"
                    width="100%" 
                    height="500" 
                    style={{ border: '0' }}
                    loading="lazy" 
                    className='p-4' 
                    title="myFrame"/>       
                </div>



            </div>
        </div>
    </section>
  )
}

export default DondeEstamos