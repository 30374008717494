import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'

const CarouselHomeGallery = ( props ) => {
  
    const [index, setIndex] = useState(props.index);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    
    return (

        <section>
            <Carousel activeIndex={index} className='carousel__box  animated fast fadeIn' indicators={ false } onSelect={ handleSelect } >
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-01.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-02.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-03.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-04.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-05.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-06.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-07.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-09.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-08.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-10.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-11.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-12.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-13.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-14.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-15.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-16.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-17.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-18.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-19.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-20.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-21.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-22.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-23.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-24.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-25.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-26.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-27.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-28.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-29.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-30.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-31.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-32.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-33.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-34.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-35.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-36.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/home/gallery/gallery-37.jpg" alt="Paseo de compras Las Alondras Carilo" loading="lazy" />  </Carousel.Item>
            </Carousel> 
        </section>

    )
}

export default CarouselHomeGallery