import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './NavBar.css'
import './Hamburger.css'
import { Modal } from 'react-bootstrap'

// import { slide as Menu } from 'react-burger-menu'

const NavBar = () => {

    const [click, setClick] = useState('');
    
    const [show, setShow] = useState(false);

    const handleClose = () => { setShow(false) }
    const handleShow = () => { setShow(true) }

    const showHamburger = () =>{
      
      
      if(click === '' ) {
        setClick('is-active')
        console.log('click: ', click)
       }
      else {

        setClick('')
        console.log('click: ', click)

      }
    
    }

    return (
        
        <div>

              <Modal
                show={show}
                size='lg'
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                >
                <Modal.Header closeButton>
                    <Modal.Title>Las Alondras Cariló.</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <iframe 
                        width="100%" 
                        height="415" 
                        src="https://www.youtube.com/embed/8n8aAyxYO-g?&autoplay=1" 
                        title="Las Alondras Carilo"
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
                </Modal.Body>

            </Modal>

            <aside className={`panel ${click}`}>
              
              <nav className="menu">
                
                <span style={{ padding: '20px 0px', marginRight: '10px'}}>
                  <button className={`hamburger hamburger--spin ${click} js-hamburger`} style={{ marginTop: '20px' }} type="button" onClick={ () => showHamburger () }>
                    <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                    </span>
                  </button>
                </span>
                
                <Link onClick={ () => setClick('')} to={'/'} className='menu__items'>Home</Link>
                <span className='menu__items--lines'></span>
                <Link onClick={ () => setClick('')}  to={'gastronomia'} className='menu__items'>Gastronomia</Link>
                <span className='menu__items--lines'></span>
                <Link onClick={ () => setClick('')} to={'locales'} className='menu__items'>Locales</Link>
                <span className='menu__items--lines'></span>
                <Link onClick={ () => setClick('')} to={'promos'} className='menu__items'>Promos</Link>
                <span className='menu__items--lines'></span>
                <Link onClick={ () => setClick('')} to={'quienes-somos'} className='menu__items'>Quienes Somos</Link>
                <span className='menu__items--lines'></span>
                <Link onClick={ () => setClick('')} to={'donde-estamos'} className='menu__items'>Donde Estamos</Link>
                <span className='menu__items--lines'></span>
                <Link onClick={ () => handleShow()} to={'donde-estamos'} className='menu__items menu__items--hamburguer'>Las Alondras desde el Drone</Link>
                
                <span className='menu__items--lines'></span>
                <small className='pt-4'><i><strong>15% off</strong> pagando<br />con tarjetas de credito.</i></small>

              </nav>
            </aside>

            <div className='navbarContainer' >
              <div className='row justify-content-between navbarContainer__row'>
                  <div className='col-1'>
                    <button className={`hamburger hamburger--spin ${click} js-hamburger`} type="button" onClick={ () => showHamburger () }>
                      <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                      </span>
                    </button>
                  </div>
                  <div className='col-9 drone__container'>
                    <img src="./images/home/las-alondras.jpg  " className="drone__container--img" alt="Drone de Las Alondras Carilo"  />
                      <p className="drone_textos footerTextos__over text-center m-3" onClick={ () => { handleShow() }}>CONONCE LAS ALONDRAS DESDE UN DRONE</p>
                    <img src="./images/footer-drone.png" className="drone__container--img" alt="Drone de Las Alondras Carilo"  />
                  </div>
                  <div className='col-2 navbar__icons'>
                  
                    <a href='https://www.instagram.com/lasalondrascarilo' target={'_blank'} rel='noreferrer noopener'>
                      <img src="./images/navbar-instagram.jpg" className='navbar__icons--image' alt="Cuenta de las Alondras en Instagram" />
                    </a>

                    <a href='mailto:info@lasalondrascarilo.com.ar ' target={'_blank'} rel='noreferrer noopener'>
                      <img src="./images/navbar-mail.jpg" className='navbar__icons--image' alt="Cuenta de las Alondras en Instagram" />
                    </a>
                    
                    <Link onClick={ () => setClick('')} to={'donde-estamos'}>
                      <img src="./images/navbar-location.jpg" className='navbar__icons--image' alt="Ubicación de las Alondras Carilo" />
                    </Link>
                  </div>
              </div>
            </div>
            
            
        </div>
        
    )
}

export default NavBar
