import React, {useState, useContext} from 'react'
import './Footer.css'
import '../../../Styles/main.css'

import SiteContext from '../../../Context/SiteContext'
import { Modal } from 'react-bootstrap'
import FooterImages from '../FooterImages/FooterImages'
import HomeGallery from '../HomeGallery/HomeGallery'


const Footer = () => {

    const [show, setShow] = useState(false);
   
    
    const handleClose = () => { setShow(false) }
    const handleShow = () => { setShow(true) }
    
    const {getSection} = useContext(SiteContext); // Busco que seccion esta seteada.
    
    const checkSection = () => {
        if( getSection() === 'Home') {
            return <HomeGallery />
        }else {
            return <div></div>
        }
    }
    
    
    return (

        <footer className='container'>
                            
            <Modal
                show={show}
                size='lg'
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                >
                <Modal.Header closeButton>
                    <Modal.Title>Las Alondras Cariló.</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <iframe 
                        width="100%" 
                        height="415" 
                        src="https://www.youtube.com/embed/8n8aAyxYO-g?&autoplay=1" 
                        title="Las Alondras Carilo"
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
                </Modal.Body>

            </Modal>

            <div className='row footer__container'>
                <div className='footer__columna'>
                    <div>
                        <a href='https://instagram.com/lasalondrascarilo' target={'_blank'} rel='noreferrer noopener'>
                            <p className='footerTextos footerTextos__izq'> 
                                INSTAGRAM 
                                <img src="./images/navbar-instagram.jpg" className="navbar__icons--image" alt="Cuenta de las Alondras en Instagram"></img>
                            </p> 
                        </a>
                        <a href='mailto:info@lasalondrascarilo.com.ar' target={'_blank'}  rel='noreferrer noopener'>
                            <p className='footerTextos  footerTextos__izq'> 
                                info@lasalondrascarilo.com.ar 
                                <img src="./images/navbar-mail.jpg" className="navbar__icons--image" alt="Cuenta de email las Alondras"></img>
                            </p>
                        </a> 
                        <p className='footerTextos footerTextos__izq'> 
                            011 4202 8796
                            <img src="./images/icono-telefono.jpg" className="navbar__icons--image" alt="Numero telefonico."></img>
                        </p>   
                    </div>
                </div>

                <div className='footer__separador footer__separador p-0'>
                    <img src='./images/footer-separador.jpg' className='d-block footer__separador--img' alt="Grafica de Las Alondras Carilo" />
                </div>

                <div className='footer__columna'>
                    <div>
                        <a href='https://www.facebook.com/lasalondrascarilo' target={'_blank'} rel='noreferrer noopener'>
                            <p className='footerTextos footerTextos__der'> 
                                <img src="./images/icono-facebook.jpg" className="navbar__icons--image" alt="Cuenta de las Alondras en Facebook"></img>
                                facebook 
                            </p> 
                        </a>
                        <p className='footerTextos footerTextos__der'> 
                            <img src="./images/icono-direccion.jpg" className="navbar__icons--image" alt="Ubicacion de Las Alondras"></img>    
                            Divisadero entre cerezo y avellano, cariló.
                        </p> 
                        <p className='footerTextos footerTextos__der'> 
                            <img src="./images/icono-horarios.jpg" className="navbar__icons--image" alt="Horarios en que estamos abierto.s"></img>
                            Todos los dias de 10 a 24 hrs
                        </p>   
                    </div>
                </div>
                <div className='text-center footer__drone__container'>
                    <img src="./images/footer-drone.png" className="footer__drone footer__drone__view" alt="Drone de Las Alondras Carilo"  />
                    {/* <p className='footerTextos footerTextos__over text-center' onClick={ () => handleShow() }><a>DRONE CAM VIDEO </a></p> */}
                </div>
            </div>  
                
            {/* CHEQUEAR EN QUE SECCION ESTA  */}
            {  checkSection() }
            
            <FooterImages />
            <div className='row p-2 pl-3 pb-5'>

                <div className='col-md-3 text-center'>
                    <a href="https://www.sancorsalud.com.ar/" target={'_blank'} rel="noreferrer" >
                        <img src="./images/header/sponsor-sancor.jpg" className='d-block w-75 header__sponsors header__sponsors--left' alt="Sancor Salud" />
                    </a>
                </div>
                
                <div className='col-md-6 text-center footerCopy'>
                    <strong>LAS ALONDRAS CARILÓ, </strong> Todos los derechos reservados ®
                    <span className='col-md-2 text-center footerCopy footerCopy__rigth'>
                        <a href='https://www.socioboutique.com.ar' target={'_blank'} rel='noreferrer noopener'>
                        SOCIO BRANDING BOUTIQUE
                        </a> 
                    </span>
                </div>

                <div className='col-md-3 text-center'>
                    <a href="https://www.icbc.com.ar/personas" target={'_blank'} rel="noreferrer" >
                        <img src="./images/header/sponsor-icbc.jpg" className='d-block w-75 header__sponsors header__sponsors--right' alt="Banco ICBC" />
                    </a>
                </div>

            </div>

        </footer>

    )
}

export default Footer
