import { createContext } from "react";

// REFERENCE of the Context
export const SiteContext = createContext();

// EXPORT nombed
export const SiteContextProvider = ({ children }) => {

    // const [footerValue, getFooter ] = useState('home');
    // const [section, setSection] = useState('')
    
    let section = ''

    const setSection = ( valueFooter ) => {
        
        section = valueFooter ;
        // console.log('CONTEXT > setSection() > valueFooter : ', valueFooter);
        return section
    
    }

    const getSection = () => {

        // console.log('CONTEXT > getSection() > section : ', section)
        return section
    
    }


    return (

        <SiteContext.Provider
            value={{
                setSection,
                getSection
            }}
            >
            {children}
        </SiteContext.Provider>

    )
}

export default SiteContext