import React from 'react'
import { Carousel } from 'react-bootstrap'

const CarouselContainer = () => {
  return (
    <section>
      <Carousel className='carousel__box  animated fast fadeIn' indicators={ false }  >
      
        
        <Carousel.Item>
            <img
            className="d-block w-100  carousel__box--image "
            src="./images/home/carousel-00.jpg"
            alt="Paseo de compras Las Alondras Carilo"
            loading="lazy"
            /> 
        </Carousel.Item>

        <Carousel.Item >
            <img
            className="d-block w-100  carousel__box--image"
            src="./images/home/carousel-02.jpg"
            alt="Paseo de compras Las Alondras Carilo"
            loading="lazy"
            />
        </Carousel.Item>

        <Carousel.Item >
            <img
            className="d-block w-100  carousel__box--image"
            src="./images/home/carousel-03.jpg"
            alt="Paseo de compras Las Alondras Carilo"
            loading="lazy"
            />
        </Carousel.Item>

        <Carousel.Item >
            <img
            className="d-block w-100  carousel__box--image"
            src="./images/home/carousel-04.jpg"
            alt="Paseo de compras Las Alondras Carilo"
            loading="lazy"
            />
        </Carousel.Item>

        <Carousel.Item >
            <img
            className="d-block w-100  carousel__box--image"
            src="./images/home/carousel-05.jpg"
            alt="Paseo de compras Las Alondras Carilo"
            loading="lazy"
            />
        </Carousel.Item>

        <Carousel.Item >
            <img
            className="d-block w-100  carousel__box--image"
            src="./images/home/carousel-06.jpg"
            alt="Paseo de compras Las Alondras Carilo"
            loading="lazy"
            />
        </Carousel.Item>

        <Carousel.Item >
            <img
            className="d-block w-100  carousel__box--image"
            src="./images/home/carousel-07.jpg"
            alt="Paseo de compras Las Alondras Carilo"
            loading="lazy"
            />
        </Carousel.Item>

        </Carousel>
    </section>
  )
}

export default CarouselContainer