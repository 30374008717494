import React, { useContext,  useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

import Loading from '../Loading/Loading'
import SiteContext from '../../Context/SiteContext'
import CarouselContainer from '../Commons/Carousel/Carousel'

import '../../Styles/main.css'
import './Home.css'

const Home = () => {    
    
    const {setSection} = useContext(SiteContext);
    setSection('Home');
    
    const [show, setShow] = useState(false);

    const handleClose = () => { setShow(false) }

    const [loading, setLoading ] = useState(true)
    useEffect(() => {
        setTimeout ( ()=>{
            setLoading(false)
            console.log('VER VIDEO')
            setShow(true)
        }, 1000 ) 
    },[])

    if(loading) { return <Loading /> }

    return ( 
        <main >
            <Modal
                show={show}
                size='lg'
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                >
                <Modal.Header closeButton>
                    <Modal.Title>Las Alondras Cariló.</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <iframe 
                        width="100%" 
                        height="415" 
                        src="https://www.youtube.com/embed/8n8aAyxYO-g?&autoplay=1" 
                        title="Las Alondras Carilo"
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
                </Modal.Body>

            </Modal>

            <CarouselContainer />
            
            <div className='container'>
                
                <div className='accesos__cont'>
                    
                    <div className='accesos__col'>
                        <Link to='/gastronomia/'>
                            <p className='accesos__textos text-center'>GASTRONOMIA</p>
                            < img src="./images/home/acceso-gastronomia.jpg" className='d-block w-100' alt="Locales gastronomicos" loading='lazy'/>
                            <div className='accesos__col__backgroundHover'>
                                INGRESAR
                            </div>
                        </Link>
                    </div>
                    
                    <div className='accesos__col'>
                        <Link to='/locales/'>
                        <p className='accesos__textos text-center'>LOCALES</p>
                            < img src="./images/home/acceso-locales.jpg" className='d-block w-100' alt="Locales" loading='lazy'/>
                        <div className='accesos__col__backgroundHover'>
                            INGRESAR
                        </div>
                        </Link>
                    </div>

                    <div className='accesos__col'>
                        <Link to='/promos/'>
                            <p className='accesos__textos text-center'>PROMOS</p>
                            < img src="./images/home/acceso-promos.jpg" className='d-block w-100' alt="Promociones" loading='lazy'/>
                            <div className='accesos__col__backgroundHover'>
                                INGRESAR
                            </div>
                        </Link>
                    </div>

                    <div className='accesos__col'> 
                        <Link to='/donde-estamos/'>
                            <p className='accesos__textos text-center'>DONDE ESTAMOS</p>
                            < img src="./images/home/acceso-donde-estamos.jpg" className='d-block w-100' alt="Donde estamos?" loading='lazy'/>
                            <div className='accesos__col__backgroundHover'>
                                INGRESAR
                            </div>
                        </Link>
                    </div>

                    <div className='accesos__col'>
                        <Link to='/quienes-somos/'>
                            <p className='accesos__textos text-center'>QUIENES SOMOS</p>
                            < img src="./images/home/acceso-quienes-somos.jpg" className='d-block w-100' alt="Quienes somos?" loading='lazy'/>
                            <div className='accesos__col__backgroundHover'>
                                INGRESAR
                            </div>
                        </Link>
                    </div>
                </div>
                
                <img src="./images/separador-horizontal.jpg" className='w-100 mb-3 separador__container d-none d-lg-block' alt="Separador" />
                <img src="./images/separador-horizontal-mobile.jpg" className='w-100 mb-5 mb-3 d-lg-none' alt="Separador" />

            </div>
    
        </main>
    )
}

export default Home
